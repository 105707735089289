import { useQueryClient } from '@tanstack/vue-query'

export const useAuth = () => {
  const queryClient = useQueryClient()

  const logout = () => {
    fetch('/sign_out').then(function () {
      queryClient.clear()
      location.reload()
    })
  }

  return {
    logout,
  }
}
